import { slugs } from '@/constants'
import { DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

/**
 * @deprecated
 * We are no longer distributing The Chosen.
 * The plan is to appeal the decision of the arbitrator. If we lose the appeal, then we can delete this code.
 * Lot's of references to The Chosen were removed in PR #6098
 * https://github.com/Angel-Studios/angel-web/pull/6098
 */
export const theChosen: ProjectTheme = {
  slug: slugs.theChosen,
  name: 'The Chosen',
  logo: 'v1658210125/angel-studios/themes/the-chosen/logo_subscription.webp',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: '#31605D',
      onPrimary: '#FFFFFF',
      secondary: '#B87B5D',
      onSecondary: '#FFFFFF',
      tertiary: '#3DE2B6',
      onTertiary: '#000000',
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: '#3DE2B6',
      onPrimary: '#000000',
      secondary: '#B87B5D',
      onSecondary: '#FFFFFF',
      tertiary: '#31605D',
      onTertiary: '#FFFFFF',
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1651006086/angel-studios/themes/the-chosen/logo_pif',
      height: 96,
      width: 300,
    },
  },
  stories: {
    filterMinChars: 100,
    sort: StorySort.TRENDING,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: true,
  },
  watch: {
    // I wanted to use the Early Access Config here in Contentful, the issue was
    // WatchEpisodeView needs this, but it uses getServerSideProps, and we don't want Contentful call there either
    seasonEarlyAccessGroup: 'the-chosen-season-3-early-access',
  },
  merch: {
    storeUrl: 'https://the-chosen-web.myshopify.com/api/2022-07/graphql.json',
    accessToken: 'eb88bfe248f2668912e39ee7c97e300d',
    handle: 'best-sellers',
  },
}
