// Nine because "thank you" is nine characters
export const DEFAULT_FILTER_MIN_CHARS = 9
export const DEFAULT_STORIES_TO_SHOW = 15

export interface MerchConfig {
  handle: string
  accessToken: string
  storeUrl: string
}

export interface MerchItemType {
  title: string
  price?: {
    currency: string
    value: number
  }
  imageCloudinaryPath: string
  url: string
}

export enum StorySort {
  TRENDING = 'TRENDING',
  NEWEST = 'NEWEST',
}

export interface ThemeColors {
  primary: string | `#{string}`
  onPrimary: string | `#{string}`
  secondary?: string | `#{string}`
  onSecondary?: string | `#{string}`
  tertiary?: string | `#{string}`
  onTertiary?: string | `#{string}`
}

export interface ColorTheme {
  themeName: 'light' | 'dark'
  colors: ThemeColors
}

export interface ProjectTheme {
  slug: string
  name: string
  logo: string
  lightTheme: ColorTheme
  darkTheme: ColorTheme
  payItForward: PayItForwardTheme
  watch?: WatchTheme
  stories: StoryTheme
  // TODO get rid of merch here. Use contentful.
  /** @deprecated This data is now definied in Contentful. Query contentful instead. */
  merch?: MerchConfig
  moviePosterUrl?: string // a Cloudinary url
  affiliateKitUrl?: string
  exhibitorKitUrl?: string
  partnershipKitUrl?: string
  ticketsPath?: string
}

export interface WatchTheme {
  seasonEarlyAccessGroup?: string
  earlyAccessUrl?: string
  tabs?: WatchTab[]
  filterNoteSize?: number
}

export type WatchTabPage = 'watch' | 'about' | 'community'

export interface WatchTab {
  page: WatchTabPage
  route: string
}

export interface PayItForwardTheme {
  logo: {
    cloudinaryPath: string
    height: number
    width: number
  }
  logoOverlayClassName?: string
}

export interface StoryTheme {
  filterMinChars: number
  sort: StorySort
  allowShareStory: boolean
  numStories: number
}
