import { slugs } from '@/constants'
import { colors } from '@/constants/colors'
import { DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

const primary = '#14273F'
const secondary = '#C24629'
const tertiary = '#EFEDEA'

export const soundOfFreedom: ProjectTheme = {
  payItForward: {
    logo: {
      cloudinaryPath: 'v1680887317/angel-app/sound-of-freedom/discovery_images/sound-of-freedom-shadow-light-4x',
      height: 56,
      width: 400,
    },
  },
  stories: {
    filterMinChars: 20,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
  slug: slugs.soundOfFreedom,
  name: 'Sound of Freedom',
  logo: 'v1680887317/angel-app/sound-of-freedom/discovery_images/sound-of-freedom-shadow-light-4x',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary,
      onPrimary: colors.white,
      secondary,
      onSecondary: colors.white,
      tertiary,
      onTertiary: colors.black,
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary,
      onPrimary: colors.white,
      secondary,
      onSecondary: colors.white,
      tertiary,
      onTertiary: colors.black,
    },
  },
}
