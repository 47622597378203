import { slugs } from '@/constants'
import { DEFAULT_FILTER_MIN_CHARS, DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

// jungle beat color guide available here: https://drive.google.com/drive/u/0/folders/1028z6FUDDUDyuX-nLVWR2dFSAJW8Pt2g

export const youngDavid: ProjectTheme = {
  slug: slugs.youngDavid,
  name: 'Young DAVID',
  logo: 'v1697488027/angel-app/young-david/discovery_images/YoungDavid_logo_10923_eng_L.webp',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: '#F99D25',
      onPrimary: '#000000',
      secondary: '#181244',
      onSecondary: '#FFF',
      tertiary: '#FFCD02',
      onTertiary: '#000',
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: '#F99D25',
      onPrimary: '#000000',
      secondary: '#181244',
      onSecondary: '#FFF',
      tertiary: '#FFCD02',
      onTertiary: '#000',
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1697488027/angel-app/young-david/discovery_images/YoungDavid_logo_10923_eng_L.webp',
      height: 176,
      width: 300,
    },
    logoOverlayClassName: 'w-1/4',
  },
  stories: {
    filterMinChars: DEFAULT_FILTER_MIN_CHARS,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
  merch: {
    storeUrl: 'https://angel-studios-inc.myshopify.com/api/2022-07/graphql.json',
    accessToken: 'ba113b3c3998ae023db05e42a85a6ac8',
    handle: 'young-david',
  },
}
