import { slugs } from '@/constants'
import { cLogger } from '../logging/client-logger'
import { twentyThreeBlast } from './23-blast'
import { the1916Project } from './1916-project'
import { aWeekAwaySeries } from './a-week-away-series'
import { afterDeath } from './afterDeath'
import { angelGuild } from './angel-guild'
import { angelStudios } from './angel-studios'
import { axiom } from './axiom'
import { beyondTheMedal } from './beyond-the-medal'
import { bonhoeffer } from './bonhoeffer'
import { braveTheDark } from './brave-the-dark'
import { cabrini } from './cabrini'
import { david } from './david'
import { dryBar } from './dry-bar-best-of'
import { fellowship } from './fellowship'
import { freelancers } from './freelancers'
import { gabrielAndTheGuardians } from './gabriel-and-the-guardians'
import { hisOnlySon } from './his-only-son'
import { homestead } from './homestead'
import { homesteadFamilySurvival } from './homestead-family-survival'
import { insideAngelStudios } from './inside-angel-studios'
import { jacob } from './jacob'
import { jungleBeat } from './jungle-beat'
import { lightwise } from './lightwise'
import { liveNotByLies } from './live-not-by-lies'
import { lostOnAMountainInMaine } from './lost-on-a-mountain-in-maine'
import { operationTripleTake } from './operation-triple-take'
import { playingForEternity } from './playing-for-eternity'
import { possumTrot } from './possum-trot'
import { quitters } from './quitters'
import { redemptionOfHenryMyers } from './redemption-of-henry-myers'
import { riot } from './riot'
import { riotAndTheDanceSeries } from './riot-and-the-dance-series'
import { sevenDaysInUtopia } from './seven-days-in-utopia'
import { sight } from './sight'
import { somebodysGottaDoIt } from './somebodys-gotta-do-it'
import { somethingToStandForWithMikeRowe } from './something-to-stand-for-with-mike-rowe'
import { soundOfFreedom } from './sound-of-freedom'
import { soundOfHopeTheStoryOfPossumTrot } from './sound-of-hope-the-story-of-possum-trot'
import { standingUp } from './standing-up'
import { surprisedByOxford } from './surprised-by-oxford'
import { sweetwater } from './sweetwater'
import { telegram } from './telegram'
import { tempest } from './tempest'
import { testament } from './testament'
import { testamentSeries } from './testament-series'
import { theChosen } from './the-chosen'
import { theGermanKing } from './the-german-king'
import { theShift } from './the-shift'
import { theWay } from './the-way'
import { toEndAllWars } from './to-end-all-wars'
import { triumphOfTheHeart } from './triumph-of-the-heart'
import { truthAndConviction } from './truth-and-conviction'
import { tuttleTwins } from './tuttle-twins'
import { ProjectTheme } from './types'
import { wingfeather } from './wingfeather-saga'
import { youngWashington } from './young-washington'
import { youngDavid } from './youngDavid'

export const ProjectToGradient: Record<string, string> = {
  [slugs.theChosen]: 'bg-brown-transparent-t-t',
  [slugs.tuttleTwins]: 'bg-teal-transparent-t-t',
  [slugs.freelancers]: 'bg-black-transparent-t-t',
  [slugs.dryBar]: 'bg-purple-transparent-t-t',
}

// NOTE This projectThemes object is hopefully a temporary stop-gap.
// The goal is to someday retrieve projectThemes from an API. Theme
// colors for a project should be considered metadata about a project,
// along with title, description, etc.
export const projectThemes: { default: ProjectTheme; [key: string]: ProjectTheme } = {
  default: angelStudios,
  [slugs.afterDeath]: afterDeath,
  [slugs.angelGuild]: angelGuild,
  [slugs.angelStudios]: angelStudios,
  [slugs.aWeekAwaySeries]: aWeekAwaySeries,
  [slugs.axiom]: axiom,
  [slugs.beyondTheMedal]: beyondTheMedal,
  [slugs.bonhoeffer]: bonhoeffer,
  [slugs.braveTheDark]: braveTheDark,
  [slugs.cabrini]: cabrini,
  [slugs.david]: david,
  [slugs.dryBar]: dryBar,
  [slugs.forYourConsideration]: angelStudios,
  [slugs.forYourConsiderationStaging]: angelStudios,
  [slugs.freelancers]: freelancers,
  [slugs.gabrielAndTheGuardians]: gabrielAndTheGuardians,
  [slugs.hisOnlySon]: hisOnlySon,
  [slugs.homestead]: homestead,
  [slugs.homesteadFamilySurvival]: homesteadFamilySurvival,
  [slugs.insideAngelStudios]: insideAngelStudios,
  [slugs.jacob]: jacob,
  [slugs.jungleBeat]: jungleBeat,
  [slugs.lightwise]: lightwise,
  [slugs.liveNotByLies]: liveNotByLies,
  [slugs.lostOnAMountainInMaine]: lostOnAMountainInMaine,
  [slugs.operationTripleTake]: operationTripleTake,
  [slugs.playingForEternity]: playingForEternity,
  [slugs.possumTrot]: possumTrot,
  [slugs.quitters]: quitters,
  [slugs.redemptionOfHenryMyers]: redemptionOfHenryMyers,
  [slugs.riot]: riot,
  [slugs.riotAndTheDanceSeries]: riotAndTheDanceSeries,
  [slugs.sevenDaysInUtopia]: sevenDaysInUtopia,
  [slugs.sight]: sight,
  [slugs.somebodysGottaDoIt]: somebodysGottaDoIt,
  [slugs.somethingToStandForWithMikeRowe]: somethingToStandForWithMikeRowe,
  [slugs.soundOfFreedom]: soundOfFreedom,
  [slugs.soundOfHopeTheStoryOfPossumTrot]: soundOfHopeTheStoryOfPossumTrot,
  [slugs.standingUp]: standingUp,
  [slugs.stagingForContent]: angelStudios,
  [slugs.surprisedByOxford]: surprisedByOxford,
  [slugs.sweetwater]: sweetwater,
  [slugs.tempest]: tempest,
  [slugs.testament]: testament,
  [slugs.testamentSeries]: testamentSeries,
  [slugs.the1916Project]: the1916Project,
  [slugs.theChosen]: theChosen,
  [slugs.fellowship]: fellowship,
  [slugs.theGermanKing]: theGermanKing,
  [slugs.theShift]: theShift,
  [slugs.telegram]: telegram,
  [slugs.theWay]: theWay,
  [slugs.toEndAllWars]: toEndAllWars,
  [slugs.triumphOfTheHeart]: triumphOfTheHeart,
  [slugs.truthAndConviction]: truthAndConviction,
  [slugs.tuttleTwins]: tuttleTwins,
  [slugs.twentyThreeBlast]: twentyThreeBlast,
  [slugs.wingfeatherSaga]: wingfeather,
  [slugs.youngDavid]: youngDavid,
  [slugs.youngWashington]: youngWashington,
}

export function getProjectTheme(projectSlug?: string | null): ProjectTheme {
  if (!projectSlug) return projectThemes?.default

  const projectTheme = projectThemes[projectSlug]

  if (!projectTheme) {
    cLogger().error('We do not have a project theme for the provided project slug!', { projectSlug })
  }

  return projectTheme ?? projectThemes.default
}

export function getProjectThemes(): ProjectTheme[] {
  return Object.values(projectThemes)
}
