import { slugs } from '@/constants'
import { DEFAULT_FILTER_MIN_CHARS, DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

export const freelancers: ProjectTheme = {
  slug: slugs.freelancers,
  name: 'Freelancers',
  logo: 'v1658209982/angel-studios/themes/freelancers/logo_subscription.webp',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: '#F2C31B',
      onPrimary: '#000000',
      secondary: '#1C9EFC',
      onSecondary: '#FFFFFF',
      tertiary: '#F3844F',
      onTertiary: '#000000',
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: '#F2C31B',
      onPrimary: '#000000',
      secondary: '#1C9EFC',
      onSecondary: '#FFFFFF',
      tertiary: '#F3844F',
      onTertiary: '#000000',
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1651006086/angel-studios/themes/freelancers/logo_pif',
      height: 154,
      width: 300,
    },
  },
  stories: {
    filterMinChars: DEFAULT_FILTER_MIN_CHARS,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
  merch: {
    storeUrl: 'https://angel-studios-inc.myshopify.com/api/2022-07/graphql.json',
    accessToken: 'ba113b3c3998ae023db05e42a85a6ac8',
    handle: 'freelancers',
  },
}
