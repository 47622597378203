import { slugs } from '@/constants'
import { DEFAULT_FILTER_MIN_CHARS, DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

export const dryBar: ProjectTheme = {
  slug: slugs.dryBar,
  name: 'Dry Bar Comedy',
  logo: 'v1658209848/angel-studios/themes/dry-bar-best-of/logo_subscription.webp',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: '#D15968',
      onPrimary: '#FFFFFF',
      secondary: '#D89473',
      onSecondary: '#FFFFFF',
      tertiary: '#F27484',
      onTertiary: '#000000',
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: '#F27484',
      onPrimary: '#000000',
      secondary: '#D89473',
      onSecondary: '#FFFFFF',
      tertiary: '#D15968',
      onTertiary: '#FFFFFF',
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1651006086/angel-studios/themes/dry-bar-best-of/logo_pif',
      height: 89,
      width: 300,
    },
  },

  stories: {
    filterMinChars: DEFAULT_FILTER_MIN_CHARS,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
  merch: {
    storeUrl: 'https://drybarcomedy.myshopify.com/api/2022-07/graphql.json',
    accessToken: '0ed4cb3443788011fd6a56b90286381c',
    handle: 'merch',
  },
}
