import { slugs } from '@/constants'
import { colors } from '@/constants/colors'
import { DEFAULT_FILTER_MIN_CHARS, DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

const night = '#151E28'
const ember = '#DE7B3A'
const silver = '#A9B9C7'

export const testament: ProjectTheme = {
  slug: slugs.testament,
  name: 'Testament',
  logo: 'v1665098643/angel-studios/themes/testament/testament-logo-black-40x40_2x',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: silver,
      onPrimary: colors.black,
      secondary: ember,
      onSecondary: colors.white,
      tertiary: night,
      onTertiary: colors.white,
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: silver,
      onPrimary: colors.black,
      secondary: ember,
      onSecondary: colors.white,
      tertiary: night,
      onTertiary: colors.white,
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1665097809/angel-studios/themes/testament/testament-logo-black-300x56',
      height: 56,
      width: 300,
    },
  },
  stories: {
    filterMinChars: DEFAULT_FILTER_MIN_CHARS,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
}
