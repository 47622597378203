import { slugs } from '@/constants'
import { colors } from '@/constants/colors'
import { DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

export const theShift: ProjectTheme = {
  slug: slugs.theShift,
  name: 'The Shift',
  logo: 'c_scale,w_40/v1637340117/angel-studios/logos/Icon-App-40x40_3x.webp',
  moviePosterUrl:
    'https://images.angelstudios.com/image/upload/v1695927927/studio-app/catalog/5ef097e0-e661-457c-bf1f-1f8cba803b59.webp',
  ticketsPath: '/tickets/the-shift',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: colors.oxide,
      onPrimary: colors.white,
      secondary: colors.copper,
      onSecondary: colors.white,
      tertiary: colors['oxide-light'],
      onTertiary: colors.black,
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: colors['oxide-light'],
      onPrimary: colors.black,
      secondary: colors.copper,
      onSecondary: colors.white,
      tertiary: colors.oxide,
      onTertiary: colors.white,
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1653568141/angel-studios/themes/for-your-consideration/logo_pif',
      height: 101,
      width: 300,
    },
  },
  stories: {
    filterMinChars: 20,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
}
