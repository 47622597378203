import { slugs } from '@/constants'
import { colors } from '@/constants/colors'
import { DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

const primary = '#14273F'
const secondary = '#C24629'
const tertiary = '#EFEDEA'

export const hisOnlySon: ProjectTheme = {
  payItForward: {
    logo: {
      cloudinaryPath: 'v1676584908/angel-app/his-only-son/HOS_FINAL_LOGO_PNG',
      height: 56,
      width: 400,
    },
  },
  stories: {
    filterMinChars: 20,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
  slug: slugs.hisOnlySon,
  name: 'His Only Son',
  logo: 'v1676584908/angel-app/his-only-son/HOS_FINAL_LOGO_PNG',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary,
      onPrimary: colors.white,
      secondary,
      onSecondary: colors.white,
      tertiary,
      onTertiary: colors.black,
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary,
      onPrimary: colors.white,
      secondary,
      onSecondary: colors.white,
      tertiary,
      onTertiary: colors.black,
    },
  },
}
