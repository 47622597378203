import { slugs } from '@/constants'
import { DEFAULT_FILTER_MIN_CHARS, DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

export const wingfeather: ProjectTheme = {
  slug: slugs.wingfeatherSaga,
  name: 'The Wingfeather Saga',
  logo: 'v1658210174/angel-studios/themes/wingfeather-saga/logo_subscription.webp',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: '#D0853B',
      onPrimary: '#FFFFFF',
      secondary: '#6BA8A9',
      onSecondary: '#000000',
      tertiary: '#74ECEE',
      onTertiary: '#000000',
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: '#74ECEE',
      onPrimary: '#000000',
      secondary: '#6BA8A9',
      onSecondary: '#000000',
      tertiary: '#D0853B',
      onTertiary: '#FFFFFF',
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1651006086/angel-studios/themes/wingfeather-saga/logo_pif',
      height: 86,
      width: 300,
    },
  },
  stories: {
    filterMinChars: DEFAULT_FILTER_MIN_CHARS,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
  watch: {
    // I wanted to use the Early Access Config here in Contentful, the issue was
    // WatchEpisodeView needs this, but it uses getServerSideProps, and we don't want Contentful call there either
    seasonEarlyAccessGroup: 'wingfeather-saga-season-1-early-access',
    earlyAccessUrl: 'https://www.angel.com/early-access/4p1NaV6s8nmHZTnvHovWZ0',
  },
  merch: {
    storeUrl: 'https://angel-studios-inc.myshopify.com/api/2022-07/graphql.json',
    accessToken: 'ba113b3c3998ae023db05e42a85a6ac8',
    handle: 'wingfeather',
  },
}
