import { slugs } from '@/constants'
import { colors } from '@/constants/colors'
import { DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

const primary = colors['oxide-light']
const secondary = colors.copper
const tertiary = colors.oxide

export const lightwise: ProjectTheme = {
  payItForward: {
    logo: {
      cloudinaryPath: 'v1678396629/angel-app/lightwise/Lightwise_BLACK',
      height: 56,
      width: 400,
    },
  },
  stories: {
    filterMinChars: 20,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
  slug: slugs.lightwise,
  name: 'Lightwise',
  logo: 'v1678396631/angel-app/lightwise/Lightwise_WHITE',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary,
      onPrimary: colors.white,
      secondary,
      onSecondary: colors.white,
      tertiary,
      onTertiary: colors.black,
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary,
      onPrimary: colors.white,
      secondary,
      onSecondary: colors.white,
      tertiary,
      onTertiary: colors.black,
    },
  },
}
