import { slugs } from '@/constants'
import { colors } from '@/constants/colors'
import { DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

export const insideAngelStudios: ProjectTheme = {
  slug: slugs.insideAngelStudios,
  name: 'Angel Studios',
  logo: 'v1683242769/angel-app/angel-studios/discovery_images/Inside-Angel-Studios.webp',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: colors.oxide,
      onPrimary: colors.white,
      secondary: colors.copper,
      onSecondary: colors.white,
      tertiary: colors['oxide-light'],
      onTertiary: colors.black,
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: colors['oxide-light'],
      onPrimary: colors.black,
      secondary: colors.copper,
      onSecondary: colors.white,
      tertiary: colors.oxide,
      onTertiary: colors.white,
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1683242769/angel-app/angel-studios/discovery_images/Inside-Angel-Studios',
      height: 178,
      width: 300,
    },
  },
  stories: {
    filterMinChars: 20,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
}
