import { slugs } from '@/constants'
import { DEFAULT_FILTER_MIN_CHARS, DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

export const playingForEternity: ProjectTheme = {
  slug: slugs.wingfeatherSaga,
  name: 'Playing for Eternity',
  logo: 'v1675360390/angel-app/Playing-for-Eternity/PlayingForEternity_logo_v03.webp',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: '#040d5e',
      onPrimary: '#FFFFFF',
      secondary: '#f3c679',
      onSecondary: '#000000',
      tertiary: '#7ba249',
      onTertiary: '#FFFFFF',
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: '#040d5e',
      onPrimary: '#FFFFFF',
      secondary: '#f3c679',
      onSecondary: '#000000',
      tertiary: '#7ba249',
      onTertiary: '#FFFFFF',
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1675360390/angel-app/Playing-for-Eternity/PlayingForEternity_logo_v03',
      height: 174,
      width: 300,
    },
  },
  stories: {
    filterMinChars: DEFAULT_FILTER_MIN_CHARS,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
}
